<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="#363636"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <div class="text-center mt-3 ml-2 mr-2">
                <v-img
                  src="https://s3.amazonaws.com/s3.dealerinsights.net/ac_logo.png"
                />
              </div>
            </div>
          </template>
          <template v-if="errorMessage">
            <div class="text-center">
              <v-alert type="error">
                {{ errorMessage }}
              </v-alert>
            </div>
          </template>
          <v-form @submit="login">
            <v-card-text class="text-center">
              <v-text-field
                v-model="email"
                color="#90C74C"
                label="Login..."
                prepend-icon="mdi-email"
              />

              <v-text-field
                v-model="password"
                class="mb-8"
                color="#90C74C"
                label="Password..."
                prepend-icon="mdi-lock-outline"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @keyup.enter="login()"
                @click:append="showPassword = !showPassword"
              />
              <pages-btn
                large
                color="#90C74C"
                @click="login"
              >
                Login
              </pages-btn>
            </v-card-text>
            <div
              class="text-center"
              style="cursor: pointer; color: blue; text-decoration: underline"
              @click="showPasswordResetDialog = true"
            >
              I forget my password!
            </div>
          </v-form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
    <template v-if="networkError">
      <v-banner
        single-line
        elevation="10"
        color="error"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            Network Error. Check your internet connection.
          </div>
        </div>
      </v-banner>
    </template>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPasswordResetDialog"
      persistent
      light
      max-width="600"
      @keydown.esc="showPasswordResetDialog = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Reset Password
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col>
              <div class="pt-9">
                <v-text-field
                  v-model="email"
                  label="What is your email address?"
                  outlined
                  rows="1"
                  required
                  :rules="[v => !!v || 'Email address is required']"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#90C74C"
            :disabled="!valid"
            @click="resetPassword()"
          >
            Reset
          </v-btn>
          <v-btn
            color="#FF4081"
            @click="showPasswordResetDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showResetMessage"
      persistent
      light
      max-width="600"
      @keydown.esc="showResetMessage = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Reset Password
        </v-card-title>
        <v-row dense>
          <v-col>
            <div class="pt-9">
              <v-alert
                border="left"
                colored-border
                color="#bc2180"
                elevation="2"
              >
                A password reset email has been sent to {{ email }}
              </v-alert>
            </div>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#90C74C"
            @click="showResetMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import titleMixin from '@/mixins/titleMixin.js'
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  export default {
    name: 'ACLogin',
    components: {
      PagesBtn: () => import('@/views/pages/components/Btn'),
      ErrorMessage,
    },
    mixins: [titleMixin],

    data: () => ({
      showErrorDialog: false,
      email: '',
      password: '',
      showPassword: false,
      errorMessage: '',
      error: '',
      networkError: false,
      loading: false,
      path: null,
      app: null,
      showPasswordResetDialog: false,
      showResetMessage: false,
      valid: false,
      loadingMessage: '',
    }),
    created () {
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      login () {
        this.loadingMessage = 'Logging in...'
        this.loading = true
        this.path = this.$route.query.path
        this.$store
          .dispatch('login', {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.loading = false
            if (this.path) {
              this.$router.push({ path: this.path })
            } else {
              this.$router.push({ name: 'Missions' })
            }
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.networkError = true
            } else {
              if (error.response.status === 401) {
                this.errorMessage = 'Invalid Login'
              } else {
                this.errorMessage = 'There was a problem authenticating you'
              }
            }
          })
      },
      resetPassword () {
        this.showPasswordResetDialog = false
        this.loadingMessage = 'Sending reset password'
        this.loading = true
        ChatovateService.resetPassword(this.email)
          .then(response => {
            this.loading = false
            this.showResetMessage = true
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
    },
  }
</script>
